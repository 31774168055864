@charset 'utf-8';

.errors {
    align-items:center;
    background-image:url('/assets/images/errorImage.jpg');
    background-position:center;
    background-repeat:no-repeat;
    background-size:cover;
    display:flex;
    height:100vh;
    justify-content:center;
    .introWrapper {
        position: absolute;
        top: 0;
        left: 0;
        height:100%;
        width:100%;
        display:flex;
        align-items: center;
    }
    .pageIntroduction {
        color:$white;
        margin-left:6%;
        transition:all ease-in-out 0.1s;
        width:35%;
        z-index:3;
        align-self:center;
        padding-top:65px;
        hr {
            background-color:$secondaryColor;
            border:0;
            width:155px;
            height:11px;
            margin:5px auto 15px 0;;
        }
        @media screen and (max-width: 1150px) {
            width:75%;
        }
        @media screen and (max-width:900px) {
          hr {
            height:9px;
          }
        }
        @media screen and (max-width:950px) {
          hr {
            height:7px;
          }
        }
    }
    @media screen and (max-width: 885px) {
        .introWrapper {
            .pageIntroduction {
                width:88%;
            }
        }
    }
}
