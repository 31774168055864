.image {
  line-height:0;
	padding:0;
  margin:0 auto;
  min-width: 600px;
	&.center {
		min-width: 800px;
    @media screen and (max-width:800px) {
      min-width:inherit;
    }
		img {
			min-width: 100%;
		}
	}
	&.full {
		min-width: 100%;
		img {
			min-width: 100%;
		}
	}
    &.left {
        float:left;
        padding-left:0;
        padding-right:2em;
    }
    &.right {
        order: 2;
        padding-left:2em;
        padding-right:0;
    }
}
