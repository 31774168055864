
@mixin global {
    body {
        font-size:20px;
        display:flex;
        flex-direction:column;
    }
    main {
      flex-grow: 1;
    }
    hr {
        background-color:$hrColor;
        border:none;
        height:1px;
        &.gray {
            background-color: $hrColorOnWhite;
            opacity: 0.6;
        }
    }
    p a {
        color:$bodyLink;
        transition: color ease-in-out $transTime;
        &:hover {
            color:$bodyLinkHover;
        }
    }
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
        transition:color ease-in-out $transTime;
    }

    .button {
        background-color:$secondaryColor;
        color:$buttonColor;
        display:inline-block;
        font-size:1.25rem;
        font-weight:600;
        text-transform:uppercase;
        padding:10px 20px;
        text-align:center;
        border:1px solid $secondaryColor;
        border-radius:5px;
        transition:background-color ease-in-out $transTime, color ease-in-out $transTime, box-shadow ease-in-out $transTime;
        &:hover {
            background-color:$buttonHover;
            color:$buttonColor;
            box-shadow:0px 2px 4px rgba(0, 0, 0, 0.4);
        }
        &.white {
            background-color:rgba(255,255,255,0);
            border:1px solid $buttonOnColor;
            color:$buttonOnColor;
            &:hover {
                background-color:rgba(255,255,255,1);
                color:$secondaryColor;
            }
        }
        @media screen and (max-width:480px) {
            font-size:0.9rem;
            padding:8px 15px;
        }
    }
    .navNews {
        text-transform:uppercase;
        transition:color ease-in-out $transTime;
        font-size:.8em;
        i.icon {
            margin-left: 5px;
            vertical-align: middle;
            margin-top: -3px;
        }
        &:hover {
            color:$secondaryColor;
        }
    }

    /// Assigns, and horizontally centers a block of content within
    /// the page. It should be used primarily on the <main> container
    /// for each page.
    /// @group classes
    /// @access public
    /// @example
    ///     <div class="centered"></div>
    .centered {
        max-width:1200px;
        margin: 0 auto;
        @media screen and (max-width:1240px) {
            padding-left:20px;
            padding-right:20px;
        }
    }


    /// Clears the floats of an object within to insure that the parent
    /// container maintains its height.
    /// @group classes
    /// @access public
    /// @example
    ///     <div class="clearfix"></div>
    .clearfix {
        &:before,&:after {
            content: '\0020';
            display: block;
            overflow: hidden;
            visibility: hidden;
            width: 0;
            height: 0;
            clear: both;
        }
    }

    .bannerLess {
        padding-top:3em;
        @media screen and (max-width:768px) {
            padding-top:4em;
        }
        @media screen and (max-width:500px) {
            padding-top:4.5em;
        }
        @media screen and (max-width:400px) {
            padding-top:5em;
        }
    }

    //Form elements
    .formRow {
      label {
        width:100%;
        display: inline-block;
        font-size:1.25rem;
        @media screen and (max-width:480px) {
            font-size:1rem;
        }
      }
      input[type=text],input[type=email],textarea {
        width:100%;
        display: inline-block;
        outline:none;
        padding:5px 10px;
        margin-bottom:15px;
        border-radius:5px;
        border:1px solid $mlspaLighterGray;
        font-size:1.25em;
        transition:border ease-in-out $transTime, color ease-in-out $transTime, background ease-in-out $transTime;

        &:focus {
          border:1px solid $primaryColor;
          background:$secondaryColor;
          color:$white;
        }
        @media screen and (max-width:768px) {
            font-size:1em;
        }
      }
      textarea {
        height:200px;
      }
      button {
        cursor:pointer;
      }
    }
}
