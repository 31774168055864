.subscribe {
    background:url('/assets/images/background-subscribe.jpg') center center $primaryColor;
    margin: 2em auto 3em;
    max-width: 800px;
    padding: 2em 6em;
    @media screen and (max-width:800px) {
        padding:2em 20px;
        margin-bottom: 0;
    }
    form {
        .formRow {
            display: flex;
            position: relative;
            vertical-align: middle;
            white-space: nowrap;
            input[type=email] {
                margin-bottom: 0;
                min-width: 66.66%;
                border-top-right-radius:0;
                border-bottom-right-radius:0;
                &::placeholder {
                    color:$primaryColor;
                }
                &:focus {
                    border:1px solid $secondaryColor;
                    border-right:1px solid $primaryColor;
                    &::placeholder {
                        color:$white;
                    }
                }
            }
            button {
                min-width: 33.33%;
                border-top-left-radius:0;
                border-bottom-left-radius:0;
            }
            @media screen and (max-width:400px) {
                input[type=email] {
                    min-width:60%;
                }
                button {
                    min-width:40%;
                }
            }
        }
    }
    hr {
        max-width: 25%;
        height:2px;
        margin:15px auto;
        background:$secondaryColor;
    }
    h3, p {
        color: $white;
        text-align: center;
    }
}
