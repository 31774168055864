@charset 'utf-8';

@mixin layout-footer {
    footer#footer {
        background-color:$primaryColor;
        .footerContainer {
            margin:0 auto;
            max-width:1200px;
            padding:40px 30px;
            a {
                color:$footerLink;
                transition:color ease-in-out $transTime;
                &:hover {
                    color:$footerLinkHover;
                }
            }
            .logoTop {
                display:flex;
                justify-content:space-between;
                align-items:center;
                hr {
                    width: calc((100%/2) - 45px);
                    border-bottom: 1px solid#d32d2d;
                    height:1px;
                }
                svg.draw {
                    width:45px;
                    margin:0 15px;
                    animation: draw 10000ms ease-in-out infinite;
                    stroke: #D32D2D;
                }
                @keyframes draw {
                    0% {stroke-dasharray: 0 50; stroke-dashoffset: 200;fill-opacity:0;stroke-width: 0.5;}
                    19% {stroke-dasharray:50 0; stroke-dashoffset: 200;fill-opacity: 0;stroke-width: 0.5;}
                    30% {fill-opacity: 1;stroke-width: 0;}
                    50% {fill-opacity: 1;stroke-width: 0;}
                    80% {fill-opacity: 1;stroke-width: 0;}
                    81% {stroke-dasharray:50 0; stroke-dashoffset: 200;fill-opacity: 0.5;stroke-width: 0.5;}
                    100% {stroke-dasharray: 0 100; stroke-dashoffset: 200;fill-opacity:0;stroke-width: 0.5;}
                }
            }
            .footer-navigation {
                display:flex;
                justify-content:space-between;
                padding-bottom:30px;
                margin-bottom:15px;
                border-bottom:1px solid $secondaryColor;
                h5 {
                    font-weight:600;
                    a {
                        color:$secondaryColor;
                    }
                }
                p,li {
                    margin-bottom:0;
                    font-size:1rem;
                }
                .footer-contact {
                    width: 25%;
                    i.icon {
                      &.mail_grey, &.phone_grey, &.marker_grey {
                        position:absolute;
                        margin-top:3px;
                        &.mail_grey {
                            margin-top:5px;
                        }
                      }
                    }
                    p {
                        padding-left:23px;
                    }
                    .social-circles {
                      margin-top:10px;
                      a {
                        transition: background ease-in-out $transTime;
                      }
                    }
                }
                .footer-links-holder {
                    width: 20%;
                    position: relative;
                    float: left;
                    margin: 0;
                    .footer-links {
                          margin:0;
                          padding:0;
                          list-style:none;
                          transition: max-height .5s;
                    }
                }
              @media screen and (max-width:700px) {
                flex-wrap:wrap;
                border-bottom:none;
                padding-bottom: 0;
                .footer-contact {
                  width:100%;
                  margin-bottom:15px;
                  padding-bottom:15px;
                  border-bottom:1px solid $secondaryColor;
                }
                .footer-links-holder {
                  width:100%;
                  margin-bottom:15px;
                  padding-bottom:15px;
                  border-bottom:1px solid $secondaryColor;
                  h5 {
                    &::after {
                      float: right;
                      margin-right: 10px;
                      content: "";
                      display: inline-block;
                      vertical-align: middle;
                      margin-top: 7px;
                      width: 7px;
                      height: 7px;
                      border-top: 2px solid $secondaryColor;
                      border-right: 2px solid $secondaryColor;
                      transform: rotate(135deg);
                      transition: transform .5s;
                    }
                  }
                  .footer-links {
                    max-height: 0;
                    overflow: hidden;
                  }
                  &.active {

                  h5 {
                    &::after {
                      transform: rotate(-45deg);
                    }
                  }
                  .footer-links {
                    max-height: 10000px;
                  }
                }
                }
              }
            }
            .footer-copyright-legal {
                display:flex;
                justify-content:space-between;
                font-size:.75em;
                .legal {
                    a {
                        padding-right:15px;
                        color:#525252;
                        &:hover {
                          color:$secondaryColor;
                        }
                    }
                }
                .copyright {
                    text-align:right;
                    color:#525252;
                }
                @media screen and (max-width:600px) {
                  flex-wrap:wrap;
                  .legal,.copyright {
                    width:100%;
                    text-align:center;
                  }
                }
            }
        }
    }
}
