// TODO: Style
// ├── typography/ (Text-Level Semantics)
// │   ├── a
// │   ├── em
// │   ├── b
// |   ├── button
// │   ├── small
// │   ├── strong
// │   ├── code
// │   ├── p
// │   ├── blockquote
// |   ├── list
// │   └── h1-h6

@mixin typography {
    blockquote {
        display:inline;
        background: $quoteBackgroundColor;
        border-left:none;
        color:$quoteColor;
        font-family:$headerFontFamily;
        font-size:2rem;
        font-weight:bold;
        margin: 2rem auto 2.5rem;
        max-width: 800px;
        padding:0;
        position: relative;
        span {
            padding: 0 20px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
        }
        &:before{
            content: "\201C";
            font-size: 50px;
            font-weight: 800;
            color: $quoteColor;
            position: absolute;
            left: -50px;
            top: -10px;
            background: $primaryColor;
            padding: 0 13px;
            max-height: 53px;
            margin-top: 10px;
        }
        &:after{
          content: "";
        }
        @media screen and (max-width:1200px) {
            font-size:1.75rem;
        }
        @media screen and (max-width:600px) {
            font-size:1.5rem;
        }
        @media screen and (max-width:400px) {
            font-size:1.15rem;
            span {
                padding: 0 10px;
            }
        }
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: $headerFontFamily;
        font-style: $headerFontStyle;
        font-weight: $headerFontWeight;
        color: $headerColor;
        text-rendering: $headerTextRendering;
        line-height:1.15em;
        margin:0;
        &.red {
            color:$secondaryColor;
        }

        small {
            line-height: 0;
            color:$headerSmallFontColor;
            font-size:.6em;
            margin-left:0.5rem;
            font-weight:100;
        }
    }

    i.icon {
        display:inline-block;
        height:25px;
        width:25px;
        background-repeat: no-repeat !important;
        transition: background-image ease-in-out $transTime;
    }
    p {
        font-size:1.25rem;
        line-height:1.75em;
        margin:0 0 1.25rem;
        @media screen and (max-width:480px) {
            font-size:1rem;
        }
    }

    .postDate {
        color: $mlspaLighterGray;
        font-weight: 500;
        font-size: 1rem;
        display:inline-block;
        transition:color ease-in-out $transTime;
    }
}
