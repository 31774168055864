// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group colors
////

/// Color Definitions
/// @type map
$colors: (
    black: #000000,
    blue: #2980b9,
    brown: #795548,
    green: #4CAF50,
    grey: #95a5a6,
    olive: #8BC34A,
    orange: #e67e22,
    pink: #E91E63,
    purple: #673AB7,
    red: #c0392b,
    teal: #1abc9c,
    violet: #9b59b6,
    yellow: #f1c40f,
    white: #ffffff
) !default;

/// Color palette.
/// @type map
$colorAssignment: (
    primary: map-get($colors, teal),
    secondary: map-get($colors, violet),
    success: map-get($colors, green),
    warning: map-get($colors, orange),
    alert: map-get($colors, red)
) !default;

/// Light shade of gray
/// @type Color
$lightGray: #e6e6e6 !default;

/// Mid level shade of gray
/// @type Color
$mediumGray: #cacaca !default;

/// High level shade of gray
/// @type Color
$darkGray: #8a8a8a !default;

// Internal variable colors
$black: map-get($colors, black) !default;
$white: map-get($colors, white) !default;

// Internal variables used for colors;
$primaryColor: map-get($colorAssignment, primary) !default;
$secondaryColor: map-get($colorAssignment, secondary) !default;
$successColor: map-get($colorAssignment, success) !default;
$warningColor: map-get($colorAssignment, warning) !default;
$alertColor: map-get($colorAssignment, alert) !default;

////
/// @group typography
////

/// Background color of the body.
/// @type Color
$bodyBackground: map-get($colors, 'white') !default;

/// Text color of the body
/// @type Color
$bodyFontColor: map-get($colors, 'black') !default;

/// Sans-Serif Front Family chain.
/// @type List
$bodySansFamily: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !default;

/// Serif Font Family chain.
/// @type List
$bodySerifFamily: 'Garamond', 'Times New Roman', serif !default;

/// Monospace Font Family chain.
/// @type List
$bodyMonoFamily: 'Courier New', 'Courier', monospace !default;

/// Set to `true` to enable antialiased font. Uses `-webkit-font-smoothing` and `-mox-osx-font-smoothing`
/// @type Boolean
$bodyAntialiased: true !default;

/// Global value used for default font weight
/// @type Keyword | Number
$globalWeightNormal: normal !default;

/// Global value used for default bold font
/// @type Keyword | Number
$globalWeightBold: bold !default;

/// Font is applied to `<html>` and `<body>` 100% Default is inherited from the user's browser;
/// @type Number
$globalFontSize: 100% !default;

/// Minimum font size for responsive scaling
/// @type Number
$globalMinFontSize: 90% !default;

/// Default leading applied to text
/// @type Number
$globalLineHeight: 1.5 !default;

/// Default kerning applied to text
/// @type Number
$globalLetterSpacing: 0.25 !default;

////
// Positioning
////

/// Global width of the site. Calculates the grid width
/// @type Number
$globalWidth: rem(1200) !default;

/// Global margin used on components
/// @type Number
$globalMargin: 1rem !default;

/// Global padding used on components
/// @type Number
$globalPadding: 1rem !default;

// Internal variables used for text direction
$global-left: left;
$global-right: right;
