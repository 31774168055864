/* Overwrite the settings of leverage.scss here.
 */

/// Color Palette.

/// @type Color
$mlspaRed: #d32d2d;

/// @type Color
$mlspaDarkRed: #AA2A2A;

/// @type Color
$mlspaDarkGray: #25282d;

/// @type Color
$mlspaLightGray: #f0f0f0;

/// @type Color
$mlspaLighterGray: #B5B5B5;

/// hr color
$mlspaLightestGray: #D8D8D8;

/// @type Color
$mlspaGray: #303338;

/// @type Color
$white:#ffffff;

/// Internal variables used for colors;

/// Primary Color
$primaryColor: $mlspaDarkGray;

/// Light Primary Color
$primaryColorLight: $mlspaLightGray;

/// Secondary Color
$secondaryColor:$mlspaRed;

/// Dark Secondary Color
$secondaryColorDark:$mlspaDarkRed;

/// Text Colors

/// blockQuote
$quoteColor:$primaryColor;

/// Primary Text Color
$textColor:$primaryColor;

/// Primary Text on Color
$textOnColor:$white;

$footerLink:#A9A9A9;
$footerLinkHover:$secondaryColor;

/// Link Colors
$headerNavLinkColor:$white;
$headerNavLinkHover:$secondaryColor;
$subMenuHover:$primaryColor;

$buttonColor:$white;
$buttonHover:$secondaryColorDark;

$buttonOnColor:$white;

$bodyLink:$secondaryColorDark;
$bodyLinkHover:$primaryColor;


/// Hr and Border Color
$hrColorOnWhite:$mlspaLighterGray;
$hrColor:$secondaryColor;

/// Blockquote Color
$quoteColor: $white;
$quoteBackgroundColor: $secondaryColor;

/// Text Shadow
/// @type Shadow
$textShadow:0 2px 4px rgba(0,0,0,0.5);

/// Global Transition
/// @group Transition
$transTime: 0.35s;

////
/// @group typography
////

/// Text color of the body
/// @type Color
$bodyFontColor: $primaryColor;

/// Sans-Serif Front Family chain.
/// @type List
$bodySansFamily: 'Mukta', Helvetica, sans-serif;

/// Serif Font Family chain.
/// @type List
$bodySerifFamily: 'Mukta', Helvetica, sans-serif;

$headerFontFaimly: 'Mukta', Helvetica, sans-serif;

$headerFontWeight: 800;


$headerMap: (
    h1: (
      320px: 40px,
      800px: 50px,
      1200px: 64px,
    ),
    h2: (
      320px: 30px,
      800px: 35px,
      1200px: 40px,
    ),
    h3: (
      320px: 20px,
      800px: 25px,
      1200px: 30px,
    ),
    h4: (
      320px: 19px,
      800px: 22px,
      1200px: 25px,
    ),
    h5: (
      320px: 16px,
      800px: 18px,
      1200px: 20px,
    ),
    h6: (
      320px: 15px,
      800px: 16px,
      1200px: 18px,
    ),
);
