// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography-helpers
////

/// Default font size for lead paragraphs.
/// @type Number
$leadFontSize: $globalFontSize * 1.25 !default;

/// Default line height for lead paragraphs
/// @type String
$leadLineHeight: 1.6 !default;

/// Default line height for subheaders.
/// @type String
$subheaderLineHeight: 1.4 !default;

/// Default font color for subheaders.
/// @type Color
$subheaderColor: $darkGray !default;

/// Default font weight for subheaders
/// @type String
$subheaderFontWeight: $globalWeightNormal;

/// Default top margin for subheaders
/// @type Number
$subheaderMarginTop: 0.2rem !default;

/// Default bottom margin for subheaders
/// @type Number
$subheaderMarginBottom: 0.5rem !default;

@mixin typography-helpers {
    ul, ol {
        &.no-bullet {
            margin-#{$global-left}: 0;
            list-style: none;
        }
    }
    
    .lead {
        font-size: $leadFontSize;
        line-height: $leadLineHeight
    }

    .subheader {
        margin-top: $subheaderMarginTop;
        margin-bottom: $subheaderMarginBottom;

        font-weight: $subheaderFontWeight;
        line-height: $subheaderLineHeight;
        color: $subheaderColor
    }
}