@charset "utf-8";

@mixin layout-header {
    body.active {
        overflow:hidden;
    }
    header#header {
        background-color: rgba(37, 41, 45, 0.95);
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: background-color ease-in-out $transTime;
        width: 100%;
        z-index: 100;
        transition: height .2s ease-in-out;

        &.open {
          height:100vh;
          background-color:transparent;
        }

        .logo {
            display: block;
            padding:19px 0 19px 20px;
            width: 175px;
        }

        nav {
            position: relative;
            z-index: 15;
            display:flex;
            align-items: center;
            &.open {
              position: absolute;
              background-color:$secondaryColor;
              top:0;
              width:calc(100vw - 100px);
              height:100vh;
              display:block;
              box-shadow:100px 0 rgba(0,0,0,.7);
              z-index: 10;
              overflow-y: scroll;
              ul.menuHolder {
                width:100%;
                li {
                  display:block;
                  border-bottom:1px solid rgba(255,255,255,0.5);
                  a {
                    padding:10px 20px;
                    width:100%;
                    svg {
                      display:none;
                    }
                  }
                  .submenu {
                    max-height:1000px;
                    opacity: 1;
                    background-color:$secondaryColorDark;
                    width:100%;
                    position: relative;

                    a {
                      opacity: 1;
                    }

                    li {
                      background-color:$secondaryColorDark;
                      color: #fff;
                      opacity: 1;
                      &:first-child {
                        border-top: 1px solid rgba(255,255,255,0.5);
                      }
                      &:last-child {
                        border-bottom: none;
                      }
                    }
                  }
                }
              }
              .social-circles {
                margin-top:7px;
                text-align:center;
                a {
                  margin-right:5px;
                }
              }
            }

            @media screen and(max-width:725px) {
              display:none;
            }

            ul.menuHolder {
                list-style: none;
                margin: 0;
                padding: 0;
                white-space: nowrap;

                li {
                    display: inline-block;
                    position: relative;
                    transition: background ease-in-out 0.1s;
                    a {
                        color: $headerNavLinkColor;
                        display: inline-block;
                        padding: 30px 20px;
                        font-size:1em;
                        @media screen and (max-width:825px) {
                          padding:30px 10px;
                        }
                        svg {
                            vertical-align:middle;
                            margin-left:5px;
                        }
                    }
                    .submenu {
                        background-color: $headerNavLinkHover;
                        position: absolute;
                        width:170px;
                        max-height:0;
                        overflow:hidden;
                        margin: 0;
                        padding: 0;
                        opacity:0;
                        transition: max-height ease-in-out 0.15s;
                        box-shadow:0 1px 1px 2px rgba(0, 0, 0, 0.05);
                        li {
                            display: block;
                            width: 100%;
                            opacity:0;
                            transition: opacity ease-in-out $transTime;
                            a {
                                color: $headerNavLinkColor;
                                line-height: 25px;
                                padding: 10px 20px;
                                width:100%;
                                transition: background-color ease-in-out $transTime;
                                &:hover {
                                    background-color: $subMenuHover;
                                }
                            }
                        }
                    }
                    &:hover {
                        background: $headerNavLinkHover;
                        .submenu {
                            display:block;
                            max-height:300px;
                            overflow:visible;
                            opacity:1;
                            li {
                                opacity:1;
                            }
                        }
                    }
                }
            }
            .social-circles {
              margin-left:5px;
              a {
                @media screen and (max-width:800px) {
                  margin-right:1px;
                }
              }
            }
        }

      $hamburger__bunHeight: 6px;
      .hamburger {
        width:40px;
        height: ($hamburger__bunHeight * 5);
        position: relative;
        margin: 0 20px 0 auto;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;
        display:none;

        @media screen and (max-width:725px) {
          display:block;
        }

        &.open {
          z-index: 15;
          align-self:flex-start;
          margin-top:20px;

          span:nth-child(1) {
            top: ($hamburger__bunHeight * 2);
            width:0;
            left: 50%;
          }

          span:nth-child(2) {
            transform: rotate(45deg);
          }

          span:nth-child(3) {
            transform: rotate(-45deg);
          }

          span:nth-child(4) {
            top: ($hamburger__bunHeight * 2);
            width:0;
            left: 50%;
          }
        }

        span {
          display: block;
          position: absolute;
          height: $hamburger__bunHeight;
          width: 100%;
          background: $secondaryColor;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2), &:nth-child(3) {
            top: ($hamburger__bunHeight * 2);
          }

          &:nth-child(4) {
            top: ($hamburger__bunHeight * 4);
          }
        }
      }
    }
}
