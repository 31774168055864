@charset 'utf-8';

@mixin social {
  .socialFeeds {
    display:flex;
    justify-content:space-between;
    padding-top:50px;
    padding-bottom:50px;
    .socialFeed {
      width:45%;
      &.instagram {
        .instagramPost {
          border-top: 1px solid rgba(15,70,100,.12);
          padding: 1em;
          img {
            margin-bottom: 0.5em;
            min-width: 100%;
          }
          p {
            font-size: 18px;
            font-weight: 300;
            line-height: 24px;
            margin-bottom: 0;
          }
          span.bold {
            font-weight: 700;
          }
          &:hover {
            background-color: rgba(160,200,220,.12);
          }
        }
      }
      &.twitter {
        .timeline-InformationCircle-widgetParent {
          display:none;
        }
      }
      .socialFeed-holder {
        max-height:800px;
        overflow:scroll;
      }
      .socialHeader {
        h2 {
          transition: color ease-in-out $transTime;
          a {
            display:flex;
            justify-content:left;
            align-items:center;
              &:hover {
              color:$secondaryColor;
              span {
                background:$secondaryColor;
              }
            }
          }
          span {
            display:flex;
            align-items:center;
            justify-content:center;
            width:35px;
            height:35px;
            margin-right:5px;
            text-align:center;
            border-radius:35px;
            background:#060607;
            transition: background ease-in-out $transTime;
            i.icon {
              vertical-align:middle;
              margin-top:2px;
            }

          }
        }
      }

    }
    @media screen and (max-width:700px) {
      flex-wrap:wrap;
      padding-top:20px;
      padding-bottom:20px;
      .socialFeed {
        width:100%;
        margin-bottom:20px;
        .socialFeed-holder {
          max-height:400px;
        }
      }
    }
    .instagram.socialFeed .socialHeader {
      padding-bottom: 1em;
    }
    #instafeed {
        height:auto;
        width:90%;
        max-width:100%;
        max-height: 800px;
        //margin-top: 3em;
        font: normal normal 14px/1.4 Helvetica,Roboto,"Segoe UI",Calibri,sans-serif;
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        @media screen and (max-width: 789px ) {
          width: 100%;
        }
    ul.instacard-content {
      margin: 0px auto;
      padding: 0;
      list-style: none;
      }
      #content {
        width:500px;
        height: auto;
        margin:0px auto;
      }
      div#instacard {
        //display: grid;
        //grid-template-columns: 1fr 1fr;
        margin-bottom: 2.75rem;
        border-bottom: 1px solid rgba(15,70,100,.12);
        width: 100%;
        padding-bottom: 2.7rem;
      }
      li.instacard-location p {
        margin-bottom: 0.5rem;
      }
      li.instacard-caption p {
        font-size: 18px;
        font-weight: 300;
        margin-top: 1.5rem;
        overflow: hidden;
        line-height:1.4em;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
      li.instacard-caption a {
        font-style: italic;
        color: $secondaryColor;
      }
      li.instacard-location h3 {
        margin-bottom: 0em;
        font-size:18px;
        font-weight:500px;
      }
      a.instagram-square img {
        width:100%;
      }

    }

  }
}
