@mixin layout-flexbox {
    .flex {
        display:flex;
        
        &.wrap {
            flex-flow: row wrap;
        }

        &.column {
            flex-direction: column;
        }

        // Justify Content
        &.align-right {
            justify-content: flex-end;
        }

        &.align-center {
            justify-content: center;
        }

        &.align-justify {
            justify-content: space-between
        }

        &.align-spaced {
            justify-content: space-around;
        }

        // Align Items
        &.align-top {
            align-items: flex-start;
        }

        &.align-bottom {
            align-items: flex-end;
        }

        &.align-middle {
            align-items: center;
        }

        &.align-stretch {
            align-items: stretch;
        }
    }
}