@charset 'utf-8';
@import url('https://fonts.googleapis.com/css?family=Mukta:400,600,800');
@import 'project';

// Global
@include leverage-css-reset;            // Reset the browser styles do nothing
@include leverage-global-styles;        // Default styles and browser fixs
@include leverage-typography;           // Typography styles

// Layout
@include foundation-xy-grid-classes;    // Foundation xy grid system: http://foundation.zurb.com/sites/docs/xy-grid.html
@include layout-flexbox;                // Flexbox helper classes
@include layout-footer;                 // Default responsive footer
@include layout-header;                 // Default responsive header

// Global
@include global;                   // Global Styles

// Typography
@include typography;               // Typography Styles

// Icons
@include icons;                     // Icon Styles

@include social;


// TEMP
.twitter-tweet {
  display: block;
  background-color:white;
  width: 700px;
  word-wrap: break-word;
  padding: 10px;
  color:#000;
  box-shadow: 2px 2px 2px rgba(0,0,0,.1);
  border: 1px solid#f0e8e8;

  margin:0 auto !important;

  &::before {
    top:0;
    margin-top:-1px;
  }
}
