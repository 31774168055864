@mixin leverage-global-styles {
    html {
        box-sizing: border-box;
        font-size: $globalFontSize;
        min-height:100vh;
    }

    *, *::before, *::after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        padding: 0;
        min-height:100vh;
        
        background: $bodyBackground;
        font-family: $bodySansFamily;
        font-weight: $globalWeightNormal;
        line-height: $globalLineHeight;
        color: $bodyFontColor;

        @if $bodyAntialiased {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    // Remove the image gap, and make them responsive
    img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        height: auto;
        -ms-interpolation-mode: bicubic;
    }

    // Make sure text area gets a height
    textarea {
        height: auto;
        min-height: 50px;
    }

    // Make sure all selects are 100% width
    select {
        box-sizing: border-box;
        width: 100%;
    }

    // Style Google maps embed correctly
    .map_canvas {
        img, embed, object {
            max-width: none !important;
        }
    }

    // Clear Default <button> styles from browsers
    button {
        padding: 0;
        appearance: none;
        border: 0;
        border-radius: 0;
        background: transparent;
        line-height: 1;
    }

    pre {
        overflow: auto;
    }

    button, input, optgroup, select, textarea {
        font-family: inherit;
    }

    // Internal show/hide element classes for javascript
    .is-visible, .ng-show {
        display: block !important;
    }

    .is-hidden, .ng-hide {
        display: none !important;
    }

    .clearfix {
        &:before, &:after {
            content: '\0020';
            display: block;
            overflow: hidden;
            visibility: hidden;
            width: 0;
            height: 0;
            clear: both;
        }
    }
}