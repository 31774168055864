// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography-base
////

// Base Typography
// - - - - - - - - - - - - - - - -
// Tags styled in this file include:
//      - Paragraph <p>
//      - Bold/italics <b> <strong> <em>
//      - Small Text <small>
//      - Headings <h1>-<h6>
//      - Links <a>
//      - Dividers <hr>
//      - List <ul> <ol>
//      - Blockquotes <blockquote>
//      - Abbreviations <abbr>

// ==============================================================
// Headings
// ==============================================================

/// Font Family for headers
/// @type String | List
$headerFontFamily: $bodySerifFamily !default;

/// Font Weight of Headers
/// @type String
$headerFontWeight: $globalWeightNormal !default;

/// Font Style (e.g. italics) of headers
/// @type String
$headerFontStyle: normal !default;

/// Color of headers
/// @type Color
$headerColor: inherit !default;

/// Line height of headers
/// @type Number
$headerLineHeight: 1.4 !default;

/// Bottom Margin of Headers.
/// @type Number
$headerMarginBottom: 0.5rem !default;

/// Text rendering method of headers
/// @type String
$headerTextRendering: optimizeLegibility !default;

/// Font size of `<small>` elements.
/// @type Number
$smallFontSize: 80% !default;

/// Color of `<small>` elements when placed inside headers.
/// @type Color
$headerSmallFontColor: $mediumGray !default;

/// Position Map for headers
/// @type Map
$headerMap: (
    h1: (
        map-get($breakpoints, small): 24px,
        map-get($breakpoints, large): 48px,
    ),
    h2: (
        map-get($breakpoints, small): 20px,
        map-get($breakpoints, large): 40px,
    ),
    h3: (
        map-get($breakpoints, small): 19px,
        map-get($breakpoints, large): 31px,
    ),
    h4: (
        map-get($breakpoints, small): 18px,
        map-get($breakpoints, large): 25px,
    ),
    h5: (
        map-get($breakpoints, small): 17px,
        map-get($breakpoints, large): 20px,
    ),
    h6: (
        map-get($breakpoints, small): 16px,
        map-get($breakpoints, large): 16px,
    ),
) !default;

// ==============================================================
// Paragraphs
// ==============================================================

/// Line height of text inside a `<p>` tag
/// @type Number
$paragraphLineHeight: 1.6 !default;

/// Bottom Margin of a paragraph
/// @type Number
$paragraphMarginBottom: 1rem !default;

/// Text rendering method of paragraphs
/// @type String
$paragraphTextRendering: optimizeLegibility !default;

// ==============================================================
// Links
// ==============================================================

/// Default color for links.
/// @type Color
$anchorColor: $primaryColor !default;

/// Default color for links on hover
/// @type Color
$anchorColorHover: scale-color($anchorColor, $lightness: -14%) !default;

/// Default text decoration for links
/// @type String
$anchorTextDecoration: none !default;

/// Default text decoration for links on hover
/// @type String
$anchorTextDecorationHover: none !default;

// ==============================================================
// Dividers
// ==============================================================

/// Maximum width of a divider
/// @type Number
$hrLarge: $globalWidth !default;

/// Max width of a SMALL divider
/// @type Number
$hrSmall: 42px !default;

/// Default border for a divider.
/// @type List
$hrBorder: 1px solid $mediumGray !default;

/// Default Margin for a divider
/// @type Number | List
$hrMargin: rem-calc(20) auto !default;

// ==============================================================
// Lists
// ==============================================================

/// Default line height for lists
/// @type Number | List
$listLineHeight: $paragraphLineHeight !default;

/// Bottom margin for items in a list
/// @type Number
$listMarginBottom: $paragraphMarginBottom !default;

/// Bullet type use for unordered lists
/// @type String
$listStyleType: disc !default;

/// Position for bullets on unordered lists
/// @type String
$listStylePosition: outside !default;

/// Left Margin for lists
/// @type Number
$listSideMargin: 1.25rem !default;

/// Nested left margin for a list inside a list
/// @type Number
$listNestedSideMargin: 1.25rem !default;

// ==============================================================
// Blockquotes
// ==============================================================

/// Text color of `<blockquote>` elements
/// @type Color
$blockQuoteColor: $darkGray !default;

/// Padding inside a `<blockquote>`
/// @type Number | List
$blockquotePadding: rem-calc(9 20 0 19) !default;

/// Default side border for `<blockquote>`
$blockquoteBorder: 1px solid $mediumGray !default;

// ==============================================================
// Abbr
// ==============================================================

/// Bottom border style for `<abbr>`
/// @type List
$abbrUnderline: 1px dotted $black !default;

@mixin typography-base {
    // Links
    a {
        line-height: inherit;
        color: $anchorColor;
        text-decoration: $anchorTextDecoration;

        cursor: pointer;

        &:hover, &:focus {
            color: $anchorColorHover;

            @if $anchorTextDecorationHover != $anchorTextDecoration {
                text-decoration: $anchorTextDecorationHover;
            }
        }

        img {
            border: 0;
        }
    }

    // Abbreviations
    abbr, abbr[title] {
        border-bottom: $abbrUnderline;
        cursor: help;
        text-decoration: none;
    }

    // Blockquotes
    blockquote {
        margin: 0 0 $paragraphMarginBottom;
        padding: $blockquotePadding;
        border-#{$global-left}: $blockquoteBorder;

        &, p {
            line-height: $paragraphLineHeight;
            color: $blockQuoteColor
        }
    }

    // Emphasized text
    em {
        font-style: italic;
        line-height: inherit;
    }

    figure {
        margin: 0;
    }

    // Headings
    h1, h2, h3, h4, h5, h6 {
        font-family: $headerFontFamily;
        font-style: $headerFontStyle;
        font-weight: $headerFontWeight;
        color: $headerColor;
        text-rendering: $headerTextRendering;

        small {
            line-height: 0;
            color:$headerSmallFontColor;
        }
    }

    @each $header, $map in $headerMap {
        #{$header} {
            @include poly-fluid-sizing('font-size', $map);
        }
    }

    // Horizontal rule
    hr {
        clear: both;

        max-width: $hrLarge;
        height: 0;
        margin: $hrMargin;

        border: none;
        border-bottom: $hrBorder;

        &.small {
            max-width: $hrSmall;
        }
    }

    // Paragraphs
    p {
        margin-bottom: $paragraphMarginBottom;
        font-size: inherit;
        line-height: $paragraphLineHeight;
        text-rendering: $paragraphTextRendering;
    }

    small {
        font-size: $smallFontSize;
        line-height: inherit;
    }

    // Strong Text
    strong, b {
        font-weight: $globalWeightBold;
        line-height: inherit;
    }

    // Lists
    ul, ol {
        margin-bottom: $listMarginBottom;
        list-style-position: $listStylePosition;
        line-height: $listLineHeight;
    }

    li {
        font-size: inherit;
    }

    ul {
        margin-#{$global-left}: $listSideMargin;
        list-style-type: $listStyleType;
    }

    ol {
        margin-#{$global-left}: $listSideMargin;
    }

    ul, ol {
        & & {
            margin-#{$global-left}: $listNestedSideMargin;
            margin-bottom: 0;
        }
    }
}
